import { PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { size } from 'lodash';

import { CONTACTS_LIST_TYPE, ContactListsState } from './contact-lists.types';

export const setSelectedSheetReducer = (
  state: ContactListsState,
  {
    payload,
  }: PayloadAction<{
    sheetControl: ContactListsState['selectedSheet']['sheetControl'];
    sheetControlContactsGid: ContactListsState['selectedSheet']['sheetControlContactsGid'];
  }>
) =>
  produce(state, (draft) => {
    const { sheetControl, sheetControlContactsGid } = payload;

    draft.selectedSheet.sheetControl = sheetControl;
    draft.selectedSheet.sheetControlContactsGid = sheetControlContactsGid;
  });

export const initializeContactsReducer = (
  state: ContactListsState,
  { payload }: PayloadAction<{ lists: CONTACTS_LIST_TYPE }>
) =>
  produce(state, (draft) => {
    const { lists } = payload;

    draft.hasContactLists = size(lists) > 0;
    draft.contactLists = size(lists) > 0 ? lists : draft.contactLists;
  });
