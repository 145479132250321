/* eslint-disable react-func/max-lines-per-function */
import { FullCampaign } from './types';
import { getFullCampaignUrl } from './urls';

import {
  TechnicalSolutionsApiHttpClient,
  TechnicalSolutionsApiResponse,
} from '@/utils/http-client';

/**
 * Retrieves the full details of a campaign by making a request to the Technical Solutions API.
 *
 * @param websiteId The ID of the website to which the campaign belongs.
 * @param campaignId The ID of the campaign for which the details are to be retrieved.
 * @returns A promise that resolves to the full details of the campaign.
 */
export async function getFullCampaign(
  websiteId: string,
  campaignId: string
): Promise<FullCampaign> {
  const client = TechnicalSolutionsApiHttpClient.instance;

  const response = await client.get<
    TechnicalSolutionsApiResponse<FullCampaign>
  >(getFullCampaignUrl(websiteId, campaignId));

  return response.result;
}
