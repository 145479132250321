import { TemplateReasonsRejectionsClient } from '@/components/translated-cell/translated-cell.type';
import {
  TemplateCategory,
  TemplateReasonsRejections,
  TemplateType,
} from '@/services/technical-solutions-api/types';
import { TemplateStates } from '@/store/slices/templates/templates.types';

export const TEMPLATE_STATUS_VALUE: TemplateStates = {
  active: 'active',
  approved: 'approved',
  deleted: 'deleted',
  disabled: 'disabled',
  drafts: 'drafts',
  failed: 'failed',
  inactive: 'inactive',
  paused: 'paused',
  pending: 'pending',
  rejected: 'rejected',
};

export const templateCategoryName = {
  // [TemplateCategory.AUTHENTICATION]: 'Autenticación',
  [TemplateCategory.UTILITY]: 'Utilidad',
  [TemplateCategory.MARKETING]: 'Marketing',
  [TemplateCategory.TRANSACTIONAL]: 'Transaccional',
};

export const templateTypeName = {
  [TemplateType.TEXT]: 'Texto',
  [TemplateType.IMAGE]: 'Imagen',
  [TemplateType.VIDEO]: 'Video',
  [TemplateType.DOCUMENT]: 'Documento',
};

export const templateReasonsRejected = {
  [TemplateReasonsRejections.ABUSIVE_CONTENT]: 'ABUSIVE_CONTENT',
  [TemplateReasonsRejections.INCORRECT_CATEGORY]: 'INCORRECT_CATEGORY',
  [TemplateReasonsRejections.INVALID_FORMAT]: 'INVALID_FORMAT',
  [TemplateReasonsRejections.SCAM]: 'SCAM',
  [TemplateReasonsRejections.NONE]: 'NONE',
  [TemplateReasonsRejectionsClient.UNKNOWN]: 'UNKNOWN',
};

export const expectedTemplateReasonsRejected = [
  templateReasonsRejected['ABUSIVE_CONTENT'],
  templateReasonsRejected['INCORRECT_CATEGORY'],
  templateReasonsRejected['INVALID_FORMAT'],
  templateReasonsRejected['NONE'],
  templateReasonsRejected['SCAM'],
  templateReasonsRejected['UNKNOWN'],
];
