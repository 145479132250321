import Cookies from 'js-cookie';

import { HttpClient } from './http-client';

import { TECHNICAL_SOLUTIONS_API_URL } from '@/constants/environment';

export interface TechnicalSolutionsApiResponse<T> {
  result: T;
  code: string;
  statusCode: number;
  description: string;
}

export interface TechnicalSolutionsApiResponseList<T> {
  result: T[];
  code: string;
  statusCode: number;
  description: string;
}

/**
 * Singleton HttpClient for WorkflowPlatform communications
 */
export class TechnicalSolutionsApiHttpClient extends HttpClient {
  private static _instance: TechnicalSolutionsApiHttpClient;

  private constructor() {
    super(TECHNICAL_SOLUTIONS_API_URL);
    this.setAuthorization(Cookies.get('jwt') || 'jwt.not.set');
  }

  public static get instance() {
    return (
      this._instance || (this._instance = new TechnicalSolutionsApiHttpClient())
    );
  }
}
