import { PROACTIVE_SENDING_VALUES } from './messages';
import { SECTIONS_ROUTES } from './routes';
import { TEMPLATE_STATUS_VALUE } from './template';

import {
  MANAGER_GROUPABLE_VIEWS_TYPE,
  Tabs,
} from '@/store/slices/layout/layout.types';

export const DRAWERABLE_MANAGER_VIEWS: MANAGER_GROUPABLE_VIEWS_TYPE = [
  SECTIONS_ROUTES.messages,
  SECTIONS_ROUTES.templates,
  SECTIONS_ROUTES.contactLists,
];

export const TABS_MANAGER_VIEWS: MANAGER_GROUPABLE_VIEWS_TYPE = [
  SECTIONS_ROUTES.messages,
  SECTIONS_ROUTES.templates,
];

export const TABS: Tabs = {
  messages: [
    {
      id: PROACTIVE_SENDING_VALUES.campaigns,
      label: `${PROACTIVE_SENDING_VALUES.campaigns}s`,
    },
    {
      id: PROACTIVE_SENDING_VALUES.notifications,
      label: `${PROACTIVE_SENDING_VALUES.notifications}s`,
    },
  ],
  templates: [
    {
      id: TEMPLATE_STATUS_VALUE.active,
      label: `${TEMPLATE_STATUS_VALUE.active}s`,
    },
    {
      id: TEMPLATE_STATUS_VALUE.inactive,
      label: `${TEMPLATE_STATUS_VALUE.inactive}s`,
    },
  ],
};
