import { Website } from '@cliengo/types';
import Cookies from 'js-cookie';

import { parseJwtCliengo } from './jwt-parser';
import { JwtCliengoPayload } from './types';

import { mockedJwt } from '@/__mocks__/services/auth/data';
import { LOGIN_URL, MOCKED_AUTH } from '@/constants/environment';
import { getHsmWebsites } from '@/services/technical-solutions-api';
import { CliengoApiHttpClient } from '@/utils/http-client';

/**
 * Redirects to login URL
 */
function redirectToLogin(): void {
  const currentURL = window.location.href;

  window.location.href = `${LOGIN_URL}?redirect=${currentURL}`;
}

/**
 * Validates the authenticity of a Cliengo JWT trying to get the websites.
 * @param jwt the JWT set in the Cookies
 * @param onAuthenticationVerified a Callback to call if the token is correct
 * @returns true in case the token is correct
 */
async function verifyAuthToken(
  jwt: string,
  onAuthenticationVerified: (jcp: JwtCliengoPayload, ws: Website[]) => void
): Promise<boolean> {
  let websites: Website[] | null = null;

  try {
    const payload: JwtCliengoPayload | undefined = parseJwtCliengo(jwt);

    if (payload) {
      websites = await getHsmWebsites(jwt);

      onAuthenticationVerified(payload, websites);
      return true;
    }
  } catch (e) {}
  return false;
}

/**
 * Sets the JWT for Cliengo-Api calls
 * @param token a valid JWT
 */
function setAuthToken(token: string): void {
  const client = CliengoApiHttpClient.instance;

  client.setAuthorization(token);
}

/**
 * Verify it the JWT in cookies is valid.
 * Sets the credentials for Cliengo-API calls in case of valid JWT.
 * Redirects to login in case of invalid JWT.
 * @param onAuthenticationVerified a Callback to call when the token is correct
 */
export async function validateJwtAndAuthorize(
  onAuthenticationVerified: (jcp: JwtCliengoPayload, ws: Website[]) => void
): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const jwt: string | undefined = MOCKED_AUTH ? mockedJwt : Cookies.get('jwt');

  if (!jwt) {
    redirectToLogin();
    return;
  }

  const result = await verifyAuthToken(jwt, onAuthenticationVerified);

  if (result) {
    setAuthToken(jwt);
    return;
  }
  redirectToLogin();
}
