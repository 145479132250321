import { createAsyncThunk } from '@reduxjs/toolkit';

import { CommonState } from '../common/common.types';

import { createContactListV2 } from '@/services/technical-solutions-api/create-contact-list-v2';
import {
  ContactListV2DTO,
  ContactListResponseDTO,
} from '@/services/technical-solutions-api/types';

interface CreateContactListAction {
  websiteId: CommonState['currentChannel'];
  contactList: ContactListV2DTO;
}

export const createContactListAction = createAsyncThunk(
  'templates/createContactList',
  async ({
    websiteId,
    contactList,
  }: CreateContactListAction): Promise<ContactListResponseDTO> => {
    const { data } = await createContactListV2(websiteId, contactList);

    return data;
  }
);
