import { createContactListV2Url } from './urls';
import { ContactListResponseDTO, ContactListV2DTO } from './types';

import {
  TechnicalSolutionsApiHttpClient,
  TechnicalSolutionsApiResponse,
} from '@/utils/http-client';

/**
 * Creates a contact list in TS Platform
 * @param websiteId
 * the cliengo website id of the website which owns the contact list
 * @param contactList an object with properties of the contact list to create
 * @returns the created contact list
 */
export async function createContactListV2(
  websiteId: string,
  body: ContactListV2DTO
): Promise<{ data: ContactListResponseDTO }> {
  const client = TechnicalSolutionsApiHttpClient.instance;
  const response = await client.post<
    TechnicalSolutionsApiResponse<{ data: ContactListResponseDTO }>
  >(createContactListV2Url(websiteId), body);

  return response.result;
}
