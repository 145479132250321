/* eslint-disable react-hooks/exhaustive-deps,
@typescript-eslint/no-unsafe-assignment,
@typescript-eslint/no-unsafe-call,
@typescript-eslint/no-unsafe-argument */
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  ButtonGroup,
  Button,
  Flex,
  Link,
} from '@chakra-ui/react';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Analytics } from 'cliengo-fe-utils';

import { DrawerContextProps, TypeHandleControl } from './drawer-context.types';

import { useAppSelector } from '@/hooks/use-redux';
import Show from '@/components/show';
import { LayoutState } from '@/store/slices/layout/layout.types';

const DrawerContext: React.FC<DrawerContextProps> = ({
  isOpen,
  onClose,
  children,
  footerHelp,
  controls,
}) => {
  const { t } = useTranslation();

  const {
    drawer: { currentSection, currentContext, contextStatus },
  } = useAppSelector<LayoutState>((state) => state.layout);

  const handleOnHelp = () => {
    if (!!footerHelp?.eventLink) {
      Analytics.trackEvent(footerHelp?.eventLink);
    }
  };

  const handleControl: TypeHandleControl = (action, trackEvent) => {
    action();
    if (!!trackEvent) {
      Analytics.trackEvent(trackEvent);
    }
  };

  return (
    <Drawer
      size={'lg'}
      onClose={onClose}
      isOpen={isOpen}
      data-testid="drawer-scope"
    >
      <DrawerOverlay />
      {currentSection && (
        <DrawerContent>
          <Show
            when={
              (!contextStatus.isEmpty && contextStatus.isHelp) ||
              (!contextStatus.isEmpty && contextStatus.isCreate)
            }
          >
            <DrawerHeader textStyle={'h3'} borderBottomWidth={'1px'}>
              {t(
                `manager.layout.drawer.sections.${camelCase(
                  currentSection ?? ''
                )}.context.${camelCase(currentContext as string)}.heading`
              )}
            </DrawerHeader>
          </Show>
          <DrawerBody padding={'0'}>{children}</DrawerBody>
          <Show
            when={
              (contextStatus.isCreate && !!controls) ||
              (contextStatus.isCreate && !!footerHelp)
            }
          >
            <DrawerFooter display={'flex'} borderTopWidth={'1px'}>
              <Show when={!!footerHelp}>
                <Flex flexBasis="auto">
                  <Link
                    color={'primary'}
                    href={footerHelp?.hrefLink}
                    isExternal={true}
                    onClick={handleOnHelp}
                  >
                    {footerHelp?.labelLink}
                  </Link>
                </Flex>
              </Show>
              <Show when={!!controls}>
                <Flex flex={1} justifyContent="flex-end">
                  <ButtonGroup>
                    <Button
                      onClick={() =>
                        handleControl(
                          !!controls
                            ? controls?.cancel.action
                            : () => {
                                return;
                              },
                          controls?.cancel.event
                        )
                      }
                    >
                      {controls?.cancel.label}
                    </Button>
                    <Button
                      onClick={() =>
                        handleControl(
                          !!controls
                            ? controls?.confirm.action
                            : () => {
                                return;
                              },
                          controls?.confirm.event
                        )
                      }
                    >
                      {controls?.confirm.label}
                    </Button>
                  </ButtonGroup>
                </Flex>
              </Show>
            </DrawerFooter>
          </Show>
        </DrawerContent>
      )}
    </Drawer>
  );
};

export default DrawerContext;
