import { useEffect, useState } from 'react';

function useIframe() {
  const [inIframe, setInIframe] = useState(false);

  useEffect(() => {
    const iframe = window !== window.parent;

    setInIframe(iframe);
  }, []);

  return { inIframe };
}

export default useIframe;
