/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps,
@typescript-eslint/no-unsafe-assignment,
@typescript-eslint/no-unsafe-call */
import { Box, Flex, Image, Tab, TabList, Tabs } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Analytics } from 'cliengo-fe-utils';

import { CLIENT_MANAGER_ROUTES } from '@/constants/routes';
import Show from '@/components/show';
import { events } from '@/utils/segment/events';
import useIframe from '@/hooks/useIframe';

/**
 * Main Navbar of the application
 * Contains Tabs for navigating to applications pages
 * @see https://chakra-templates.dev/navigation/navbar
 * @see https://chakra-ui.com/docs/components/tabs#controlled-tabs
 */
export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { inIframe } = useIframe();

  const routes = [
    CLIENT_MANAGER_ROUTES.messages,
    CLIENT_MANAGER_ROUTES.templates,
    CLIENT_MANAGER_ROUTES.contactLists,
    CLIENT_MANAGER_ROUTES.blacklist,
  ];

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    // @ts-ignore
    let index = routes.indexOf(location.pathname);

    // if the routes donot contain the pathname, then index is -1
    index = index < 0 ? 0 : index;
    setTabIndex(index);
  }, []);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    navigate(routes[index]);
    Analytics.trackEvent(events.manager.layout.navbar.tabs[index]);
  };

  return (
    <Box
      borderBottom={1}
      borderStyle={'solid'}
      borderColor={'gray.200'}
      shadow={'lg'}
      py={'0.5'}
      width={'100%'}
    >
      <Flex align={'center'} px="24px">
        <Show when={!inIframe}>
          <Flex my={3} w={'130px'}>
            <Image
              fit={'contain'}
              src={
                'https://res.cloudinary.com/hbrrdozyj/image/upload/v1558546804/cliengo819x195_zxegqs.png'
              }
            />
          </Flex>
        </Show>
        <Flex flex={1} justifyContent={inIframe ? 'flex-start' : 'flex-end'}>
          <Tabs
            variant={'soft-rounded'}
            colorScheme={'primary'}
            mx={4}
            index={tabIndex}
            onChange={handleTabsChange}
          >
            <TabList>
              <div id="messages-tab">
                <Tab>{t('manager.layout.navbar.tabs.messages')}</Tab>
              </div>

              <div id="templates-tab">
                <Tab>{t('manager.layout.navbar.tabs.templates')}</Tab>
              </div>

              <div id="contact-lists-tab">
                <Tab>{t('manager.layout.navbar.tabs.contact_list')}</Tab>
              </div>
              <Tab>{t('manager.layout.navbar.tabs.blocked_numbers')}</Tab>
            </TabList>
          </Tabs>
        </Flex>
      </Flex>
    </Box>
  );
};
