import { Analytics } from 'cliengo-fe-utils';
import { Account, User } from '@cliengo/types';

import { SEGMENT_WRITE_KEY } from '@/constants/environment';

export const analitycsInitialize = (userData: User, accountData: Account) => {
  Analytics.initialize({
    segmentWriteKey: SEGMENT_WRITE_KEY,
    sendAnalytics: 'YES',
    user: userData as unknown as JsonObject,
    account: accountData as unknown as JsonObject,
  });
};
