import { uploadTemplateMediaUrl } from './urls';
import { TemplateType } from './types';

import {
  TechnicalSolutionsApiHttpClient,
  TechnicalSolutionsApiResponse,
} from '@/utils/http-client';

interface UploadTemplateMediaParams {
  websiteId: string;
  templateType: TemplateType;
  file: File;
}

export async function uploadTemplateMedia({
  websiteId,
  templateType,
  file,
}: UploadTemplateMediaParams): Promise<string> {
  const client = TechnicalSolutionsApiHttpClient.instance;

  if (
    ![TemplateType.IMAGE, TemplateType.VIDEO, TemplateType.DOCUMENT].includes(
      templateType
    )
  ) {
    throw new Error('Template type is not Multimedia');
  }

  const formData = new FormData();

  formData.append('file', file);
  formData.append('templateType', templateType);

  const url1 = uploadTemplateMediaUrl(websiteId);
  const mediaFileResponse = await client.postFormData<
    TechnicalSolutionsApiResponse<{ mediaId: string }>
  >(url1, formData);

  return mediaFileResponse.result.mediaId;
}
