import { createSlice } from '@reduxjs/toolkit';

import { ContactListsState } from './contact-lists.types';
import {
  initializeContactsReducer,
  setSelectedSheetReducer,
} from './contact-lists.reducers';
import { createContactListAction } from './contact-lists.async-actions';

import { asyncThunkStatus } from '@/constants/common';

export const initialState: ContactListsState = {
  hasContactLists: false,
  contactLists: [],
  useSheet: false,
  selectedSheet: {
    sheetControl: '',
    sheetControlContactsGid: '',
  },
  createContactListStatus: asyncThunkStatus.idle,
};

export const contactListsSlice = createSlice({
  name: 'contactLists',
  initialState,
  reducers: {
    initializeContacts: initializeContactsReducer,
    setSelectedSheet: setSelectedSheetReducer,
  },
  extraReducers(builder) {
    builder.addCase(
      createContactListAction.pending,
      (state: ContactListsState) => {
        state.createContactListStatus = asyncThunkStatus.pending;
      }
    );
    builder.addCase(
      createContactListAction.fulfilled,
      (state: ContactListsState) => {
        state.createContactListStatus = asyncThunkStatus.succeeded;
      }
    );
    builder.addCase(
      createContactListAction.rejected,
      (state: ContactListsState) => {
        state.createContactListStatus = asyncThunkStatus.failed;
      }
    );
  },
});

export const { setSelectedSheet, initializeContacts } =
  contactListsSlice.actions;

export default contactListsSlice.reducer;
