import { AsyncThunkStatus } from '@/store/slices/common/common.types';
import { TemplateStates } from '@/store/slices/templates/templates.types';

export const TEMPLATES_STATUS: TemplateStates = {
  active: 'active',
  approved: 'approved',
  deleted: 'deleted',
  disabled: 'disabled',
  drafts: 'drafts',
  failed: 'failed',
  inactive: 'inactive',
  paused: 'paused',
  pending: 'pending',
  rejected: 'rejected',
};

export const asyncThunkStatus = {
  [AsyncThunkStatus.idle]: 'idle',
  [AsyncThunkStatus.pending]: 'pending',
  [AsyncThunkStatus.succeeded]: 'succeeded',
  [AsyncThunkStatus.failed]: 'failed',
};
