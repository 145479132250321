import { PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { MessagesState } from './messages.types';

import { Campaign } from '@/services/technical-solutions-api/types';

export const setCampaignsReducer = (
  state: MessagesState,
  {
    payload,
  }: PayloadAction<{
    campaigns: MessagesState['campaigns'];
    stats: MessagesState['stats'];
  }>
) =>
  produce(state, (draft) => {
    const { campaigns, stats } = payload;

    draft.campaigns = campaigns;
    draft.stats = stats;
  });

export const setNotificationsReducer = (
  state: MessagesState,
  {
    payload,
  }: PayloadAction<{
    notifications: MessagesState['notifications'];
  }>
) =>
  produce(state, (draft) => {
    const { notifications } = payload;

    draft.notifications = notifications;
  });

export const updateDateRangeReducer = (
  state: MessagesState,
  { payload }: PayloadAction<{ ranges: MessagesState['dateRange'] }>
) =>
  produce(state, (draft) => {
    const { ranges } = payload;

    draft.dateRange = ranges;
  });

export const setCurrentCampaignReducer = (
  state: MessagesState,
  {
    payload,
  }: PayloadAction<{ currentCampaign: MessagesState['currentCampaign'] }>
) =>
  produce(state, (draft) => {
    const { currentCampaign } = payload;

    draft.currentCampaign = currentCampaign;
  });

export const addNewCampaignReducer = (
  state: MessagesState,
  { payload }: PayloadAction<{ campaign: Campaign }>
) =>
  produce(state, (draft) => {
    const { campaign } = payload;

    draft.campaigns = [campaign, ...state.campaigns];
  });
