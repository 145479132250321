import { omit } from 'lodash';

import {
  ClientManagerRoutes,
  RootRoutes,
  SectionsRoutes,
} from '@/types/routes.types';
import { DrawerContexts } from '@/store/slices/layout/layout.types';

export const ROOT_ROUTES: RootRoutes = {
  home: 'home',
  dashboard: 'dashboard',
};

export const SECTIONS_ROUTES: SectionsRoutes = {
  messages: 'messages',
  templates: 'templates',
  contactLists: 'contactLists',
  blacklist: 'blacklist',
};

export const CLIENT_MANAGER_ROUTES: ClientManagerRoutes = {
  messages: `${SECTIONS_ROUTES.messages}`,
  templates: `${SECTIONS_ROUTES.templates}`,
  contactLists: `${SECTIONS_ROUTES.contactLists}`,
  blacklist: `${SECTIONS_ROUTES.blacklist}`,
};

export const CLIENT_DASHBOARD_ROUTES = omit(CLIENT_MANAGER_ROUTES, ['home']);

export const DRAWER_CONTEXTS: DrawerContexts = {
  create: 'create',
  update: 'update',
  help: 'help',
  empty: 'empty',
};
