/* eslint-disable react-func/max-lines-per-function */
import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { MessagesState } from './messages.types';
import {
  setCampaignsReducer,
  setNotificationsReducer,
  updateDateRangeReducer,
  setCurrentCampaignReducer,
  addNewCampaignReducer,
} from './messages.reducers';
import {
  fetchCampaignsAndStatsAction,
  fetchCurrentCampaignAction,
  fetchNotificationEventsAction,
  resendPendingMessagesAction,
} from './messages.async-actions';

import { defaultStats } from '@/services/technical-solutions-api/constants';
import { asyncThunkStatus } from '@/constants/common';

export const initialState: MessagesState = {
  dateRange: [dayjs().date(1).toDate(), dayjs().toDate()],
  campaigns: [],
  notifications: [],
  stats: defaultStats,
  currentCampaign: null,
  fetchCampaignsAndStatsStatus: asyncThunkStatus.idle,
  fetchNotificationEventsStatus: asyncThunkStatus.idle,
  resendPendingMessages: asyncThunkStatus.idle,
  fetchCurrentCampaign: asyncThunkStatus.idle,
};

export const messagesSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setCampaigns: setCampaignsReducer,
    setNotifications: setNotificationsReducer,
    setCurrentCampaign: setCurrentCampaignReducer,
    updateDateRange: updateDateRangeReducer,
    addNewCampaign: addNewCampaignReducer,
  },
  extraReducers(builder) {
    builder.addCase(
      fetchCampaignsAndStatsAction.pending,
      (state: MessagesState) => {
        state.fetchCampaignsAndStatsStatus = asyncThunkStatus.pending;
      }
    );
    builder.addCase(
      fetchCampaignsAndStatsAction.fulfilled,
      (state: MessagesState) => {
        state.fetchCampaignsAndStatsStatus = asyncThunkStatus.succeeded;
      }
    );
    builder.addCase(
      fetchCampaignsAndStatsAction.rejected,
      (state: MessagesState) => {
        state.fetchCampaignsAndStatsStatus = asyncThunkStatus.failed;
      }
    );
    builder.addCase(
      fetchNotificationEventsAction.pending,
      (state: MessagesState) => {
        state.fetchNotificationEventsStatus = asyncThunkStatus.pending;
      }
    );
    builder.addCase(
      fetchNotificationEventsAction.fulfilled,
      (state: MessagesState) => {
        state.fetchNotificationEventsStatus = asyncThunkStatus.succeeded;
      }
    );
    builder.addCase(
      fetchNotificationEventsAction.rejected,
      (state: MessagesState) => {
        state.fetchNotificationEventsStatus = asyncThunkStatus.failed;
      }
    );
    builder.addCase(
      fetchCurrentCampaignAction.pending,
      (state: MessagesState) => {
        state.fetchCurrentCampaign = asyncThunkStatus.pending;
      }
    );
    builder.addCase(
      fetchCurrentCampaignAction.fulfilled,
      (state: MessagesState) => {
        state.fetchCurrentCampaign = asyncThunkStatus.succeeded;
      }
    );
    builder.addCase(
      fetchCurrentCampaignAction.rejected,
      (state: MessagesState) => {
        state.fetchCurrentCampaign = asyncThunkStatus.failed;
      }
    );
    builder.addCase(
      resendPendingMessagesAction.pending,
      (state: MessagesState) => {
        state.resendPendingMessages = asyncThunkStatus.pending;
      }
    );
    builder.addCase(
      resendPendingMessagesAction.fulfilled,
      (state: MessagesState) => {
        state.resendPendingMessages = asyncThunkStatus.succeeded;
      }
    );
    builder.addCase(
      resendPendingMessagesAction.rejected,
      (state: MessagesState) => {
        state.resendPendingMessages = asyncThunkStatus.failed;
      }
    );
  },
});

export const {
  updateDateRange,
  setCampaigns,
  setNotifications,
  setCurrentCampaign,
  addNewCampaign,
} = messagesSlice.actions;

export default messagesSlice.reducer;
