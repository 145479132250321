/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react-func/max-lines-per-function */
import { createSlice } from '@reduxjs/toolkit';

import { TemplatesState } from './templates.types';
import {
  updateTemplatesReducer,
  setLoadingTemplatesReducer,
  selectTemplateReducer,
  unselectTemplateReducer,
  addNewTemplateReducer,
} from './templates.reducers';
import {
  createTemplateAction,
  fetchTemplatesAction,
  deactivateTemplateAction,
  updateTemplateMediaAction,
} from './template.async-actions';

import { asyncThunkStatus } from '@/constants/common';

export const initialState: TemplatesState = {
  templates: {
    active: [],
    inactive: [],
    drafts: [],
  },
  currentTemplate: null,
  templatesStatus: {
    hasActive: false,
    hasInactive: false,
    hasDrafts: false,
  },
  isLoadingTemplates: false,
  fetchTemplatesStatus: asyncThunkStatus.idle,
  createTemplateStatus: asyncThunkStatus.idle,
  deactivateTemplateStatus: asyncThunkStatus.idle,
  updateTemplateMediaStatus: asyncThunkStatus.idle,
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    updateTemplates: updateTemplatesReducer,
    selectTemplate: selectTemplateReducer,
    unselectTemplate: unselectTemplateReducer,
    setLoadingTemplates: setLoadingTemplatesReducer,
    addNewTemplate: addNewTemplateReducer,
  },
  extraReducers(builder) {
    builder.addCase(fetchTemplatesAction.pending, (state: TemplatesState) => {
      state.isLoadingTemplates = true;
      state.fetchTemplatesStatus = asyncThunkStatus.pending;
    });
    builder.addCase(
      fetchTemplatesAction.fulfilled,
      (state: TemplatesState, action) => updateTemplatesReducer(state, action)
    );
    builder.addCase(fetchTemplatesAction.rejected, (state: TemplatesState) => {
      state.isLoadingTemplates = false;
      state.fetchTemplatesStatus = asyncThunkStatus.failed;
    });
    builder.addCase(createTemplateAction.pending, (state: TemplatesState) => {
      state.createTemplateStatus = asyncThunkStatus.pending;
    });
    builder.addCase(createTemplateAction.fulfilled, (state: TemplatesState) => {
      state.createTemplateStatus = asyncThunkStatus.succeeded;
    });
    builder.addCase(createTemplateAction.rejected, (state: TemplatesState) => {
      state.createTemplateStatus = asyncThunkStatus.failed;
    });

    builder.addCase(
      deactivateTemplateAction.pending,
      (state: TemplatesState) => {
        state.isLoadingTemplates = true;
        state.deactivateTemplateStatus = asyncThunkStatus.pending;
      }
    );
    builder.addCase(
      deactivateTemplateAction.fulfilled,
      (state: TemplatesState) => {
        state.isLoadingTemplates = false;
        state.deactivateTemplateStatus = asyncThunkStatus.succeeded;
      }
    );
    builder.addCase(
      deactivateTemplateAction.rejected,
      (state: TemplatesState) => {
        state.isLoadingTemplates = false;
        state.deactivateTemplateStatus = asyncThunkStatus.failed;
      }
    );

    builder.addCase(
      updateTemplateMediaAction.pending,
      (state: TemplatesState) => {
        state.isLoadingTemplates = true;
        state.updateTemplateMediaStatus = asyncThunkStatus.pending;
      }
    );
    builder.addCase(
      updateTemplateMediaAction.fulfilled,
      (state: TemplatesState) => {
        state.isLoadingTemplates = false;
        state.updateTemplateMediaStatus = asyncThunkStatus.succeeded;
      }
    );
    builder.addCase(
      updateTemplateMediaAction.rejected,
      (state: TemplatesState) => {
        state.isLoadingTemplates = false;
        state.updateTemplateMediaStatus = asyncThunkStatus.failed;
      }
    );
  },
});

export const {
  updateTemplates,
  selectTemplate,
  unselectTemplate,
  setLoadingTemplates,
  addNewTemplate,
} = templatesSlice.actions;

export default templatesSlice.reducer;
