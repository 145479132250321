import { TemplateResponseDto } from './types';
import { getTemplatesUrl } from './urls';

import {
  TechnicalSolutionsApiHttpClient,
  TechnicalSolutionsApiResponseList,
} from '@/utils/http-client';

/**
 * Retrieves templates for a specific website from the Technical Solutions API.
 *
 * @param websiteId The ID of the website for which templates are being retrieved.
 * @returns A promise that resolves to an array of TemplateResponseDto objects representing the templates.
 */
export async function getTemplates(
  websiteId: string
): Promise<TemplateResponseDto[]> {
  const client = TechnicalSolutionsApiHttpClient.instance;

  const { result: templates } = await client.get<
    TechnicalSolutionsApiResponseList<TemplateResponseDto>
  >(getTemplatesUrl(websiteId));

  return templates;
}
