import { TemplateReasonsRejections } from '@/services/technical-solutions-api/types';
import { EventFailureReasonCodes } from '@/services/technical-solutions-platform/types';

export enum TemplateReasonsRejectionsClient {
  UNKNOWN = 'UNKNOWN',
}

export enum EventFailureReasonCodesClient {
  UNKNOWN = 'UNKNOWN',
}

export type REASONS_REJECTION_TYPE =
  | TemplateReasonsRejections[keyof TemplateReasonsRejections]
  | TemplateReasonsRejectionsClient.UNKNOWN;

export type EVENT_FAILURE_REASON_CODE_TYPE =
  | typeof EventFailureReasonCodes
  | EventFailureReasonCodesClient.UNKNOWN;

export type SELECTABLE_KEYS =
  | REASONS_REJECTION_TYPE
  | EVENT_FAILURE_REASON_CODE_TYPE;

export interface TranslatedCellProps<SELECTED_KEY> {
  expectedKeys: Array<SELECTABLE_KEYS>;
  notationTrace: string;
  translatedKey: SELECTED_KEY;
}
