/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/unbound-method */
import { Account, User } from '@cliengo/types';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import 'driver.js/dist/driver.css';
import './tour/driver.css';

import { analitycsInitialize } from '@/initializers/analitycs';
import router from '@/router';

export const App: React.FC = () => {
  const init = (): void => {
    analitycsInitialize({} as User, {} as Account);
  };

  useEffect(() => {
    init();
  }, []);

  return <RouterProvider router={router} />;
};
