import dayjs from 'dayjs';

const DATE_FORMAT = 'YYYY-MM-DD';

export const getCampaignByIdUrl = (campaignId: number) =>
  `/hsm-campaigns/${campaignId}?populate[0]=website&populate[1]=template`;

export const getEventsByCampaignIdUrl = (campaignId: number) =>
  `/hsm-events?filters[hsm_campaign][id][$eq]=${campaignId}&pagination[limit]=10000`;

export const getNotificationEventsUrl = (
  websiteId: string,
  dateStart: Date,
  dateEnd: Date
): string =>
  `/hsm-events?populate[0]=template&filters[type][$eq]=notification&filters[website][websiteId][$eq]=${websiteId}&filters[createdAt][$gte]=${dayjs(
    dateStart
  ).format(DATE_FORMAT)}&filters[createdAt][$lte]=${dayjs(dateEnd)
    .add(1, 'day')
    .format(DATE_FORMAT)}&pagination[limit]=10000&sort=createdAt:desc`;

export const getHsmEventsUrl = (websiteId: string) =>
  `/hsm-events?populate[0]=hsm_campaign&populate[1]=template&filters[website][websiteId][$eq]=${websiteId}&pagination[limit]=10000&sort=updatedAt:desc`;

/**
 * returns the URL for get hsm-events between dates
 * @param websiteId
 * @param dateStart date from
 * @param dateEnd date to (inclusive)
 * @returns URL
 */
export const getHsmEventsDatesUrl = (
  websiteId: string,
  dateStart: Date,
  dateEnd: Date
) =>
  `/hsm-events?populate[0]=hsm_campaign&populate[1]=template&filters[website][websiteId][$eq]=${websiteId}&filters[updatedAt][$gte]=${dayjs(
    dateStart
  ).format(DATE_FORMAT)}&filters[updatedAt][$lte]=${dayjs(dateEnd)
    .add(1, 'day')
    .format(DATE_FORMAT)}&pagination[limit]=10000&sort=updatedAt:desc`;

export const getAllHsmConfigUrl = `hsm-configs?populate[0]=website&pagination[limit]=100000&sort=createdAt:desc`;

/**
 * returns URL for get HsmConfig object
 * @param websiteId
 * @returns URL
 */
export const getHsmConfigUrl = (websiteId: string) =>
  `hsm-configs?populate[0]=website&filters[website][websiteId][$eq]=${websiteId}&pagination[limit]=1&sort=createdAt:desc`;

/**
 * returns the URL for get templates
 * @param websiteId
 * @returns URL
 */
export const getTemplatesUrl = (websiteId: string) =>
  `/templates?filters[website][websiteId][$eq]=${websiteId}&pagination[limit]=10000&sort=createdAt:desc`;

/**
 * returns the URL for get contacts
 * @param websiteId
 * @returns URL
 */
export const getContactListsUrl = (websiteId: string) =>
  `/contact-lists?populate=contacts&filters[website][websiteId][$eq]=${websiteId}&pagination[limit]=10000&sort=createdAt:desc`;

/**
 * returns URL for get HsmConfig object
 * @param websiteId
 * @returns URL
 */
export const getBlacklistUrl = (websiteId: string) =>
  `blacklists?populate[0]=website&populate[1]=blacklist_phones&filters[website][websiteId][$eq]=${websiteId}&pagination[limit]=1`;
