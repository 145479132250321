/* eslint-disable 
@typescript-eslint/no-unsafe-member-access,
@typescript-eslint/no-unsafe-assignment,
@typescript-eslint/no-unsafe-call */
import { lazy, Suspense } from 'react';
import { Flex } from '@chakra-ui/react';
import { camelCase } from 'lodash';

import { MANAGER_DRAWER_CONTENTS_TYPE } from './dreawer-content.type';

import { useAppSelector } from '@/hooks/use-redux';
import {
  LayoutState,
  MANAGER_VIEW_KEY_TYPE,
} from '@/store/slices/layout/layout.types';
import DefaultProgress from '@/components/default-progress';

const DrawerContentMessages = lazy(
  () => import('@/components/drawer-content-messages')
);

const DrawerContentTemplates = lazy(
  () => import('@/components/drawer-content-templates')
);

const DrawerContentContactLists = lazy(
  () => import('@/components/drawer-content-contact-lists')
);

const dashboardContents: MANAGER_DRAWER_CONTENTS_TYPE = {
  messages: <DrawerContentMessages />,
  templates: <DrawerContentTemplates />,
  contactLists: <DrawerContentContactLists />,
};

const DrawerContent = () => {
  const {
    drawer: { currentSection },
  } = useAppSelector<LayoutState>((state) => state.layout);

  return (
    <Suspense
      fallback={
        <Flex
          flexDirection={'column'}
          flex={1}
          justifyContent={'center'}
          alignItems={'center'}
          height="100%"
        >
          <DefaultProgress />
        </Flex>
      }
    >
      {dashboardContents[camelCase(currentSection) as MANAGER_VIEW_KEY_TYPE]}
    </Suspense>
  );
};

export default DrawerContent;
