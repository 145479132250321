import { PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import {
  MANAGER_VIEW_VALUE_TYPE,
  DRAWER_CONTEXT_VALUE_TYPE,
  LayoutState,
} from './layout.types';
import {
  isDrawerableView,
  isTabsView,
  updateContextStatus,
} from './layout.utils';

import { TABS } from '@/constants/layout';

export const initializeLayoutReducer = (
  state: LayoutState,
  {
    payload,
  }: PayloadAction<{
    managerView: MANAGER_VIEW_VALUE_TYPE;
    selectedContext: DRAWER_CONTEXT_VALUE_TYPE;
  }>
) =>
  produce(state, (draft) => {
    const {
      drawer: { contextStatus },
    } = state;

    const { managerView, selectedContext } = payload;

    draft.currentView = managerView;
    draft.hasTabs = isTabsView(managerView);
    draft.tabs = {
      currentTab: isTabsView(managerView) && TABS[managerView][0].id,
      items: isTabsView(managerView) ? TABS[managerView] : null,
    };
    draft.hasDrawer = isDrawerableView(managerView);
    draft.drawer.currentSection = managerView;
    draft.drawer.currentContext = selectedContext ?? 'empty';
    draft.drawer.contextStatus = updateContextStatus(
      contextStatus,
      selectedContext
    );
  });

export const toggleShowDrawerReducer = (state: LayoutState) =>
  produce(state, (draft) => {
    const {
      drawer: { isOpen },
    } = state;

    draft.drawer.isOpen = !isOpen;
  });

export const setLoadingReducer = (
  state: LayoutState,
  { payload }: PayloadAction<LayoutState['isLoading']>
) =>
  produce(state, (draft) => {
    draft.isLoading = payload;
  });
