import { Center, CircularProgress } from '@chakra-ui/react';

export const DefaultProgress = ({
  size = '100px',
  ...additionalProps
}: {
  size?: string;
}) => {
  return (
    <Center {...additionalProps}>
      <CircularProgress size={size} isIndeterminate color={'primary.400'} />
    </Center>
  );
};
