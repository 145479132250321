import { HttpClient } from './http-client';

import {
  TECHNICAL_SOLUTIONS_PLATFORM_TOKEN,
  TECHNICAL_SOLUTIONS_PLATFORM_URL,
} from '@/constants/environment';

export interface TSPlatformListResponse<T> {
  data: T[];
  meta: {
    pagination: {
      start: number;
      limit: number;
      total: number;
    };
  };
}

export interface TSPlatformSingleResponse<T> {
  data: T;
  meta: {
    pagination: {
      start: number;
      limit: number;
      total: number;
    };
  };
}

/**
 * Singleton HttpClient for TechnicalSolutionsPlatform communications
 */
export class TechnicalSolutionsPlatformHttpClient extends HttpClient {
  private static _instance: TechnicalSolutionsPlatformHttpClient;

  private constructor() {
    super(TECHNICAL_SOLUTIONS_PLATFORM_URL);
    this.setAuthorization(TECHNICAL_SOLUTIONS_PLATFORM_TOKEN);
  }

  public static get instance() {
    return (
      this._instance ||
      (this._instance = new TechnicalSolutionsPlatformHttpClient())
    );
  }
}
