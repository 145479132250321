import { Website } from '@cliengo/types';

import { LIMIT_TIER } from '@/services/technical-solutions-api/types';
import { TechnicalSolutionsApiResponse } from '@/utils/http-client';

export enum AsyncThunkStatus {
  idle = 'idle',
  pending = 'pending',
  succeeded = 'succeeded',
  failed = 'failed',
}

export type ASYNC_ACTION_STATUS_TYPE = AsyncThunkStatus[keyof AsyncThunkStatus];

export type TS_API_REJECT_VALUE_TYPE = TechnicalSolutionsApiResponse<unknown>;

interface ChannelState {
  gupshupClient: boolean;
}

export interface CommonState {
  currentChannel: Website['id'];
  gupshupClient: ChannelState['gupshupClient'];
  tierCurrentLimit: Nullable<LIMIT_TIER>;
  fetchAccountLimitStatus: ASYNC_ACTION_STATUS_TYPE;
}
