import dayjs from 'dayjs';

import { StrapiHsmEvent } from './types';
import { getNotificationEventsUrl } from './urls';

import {
  TechnicalSolutionsPlatformHttpClient,
  TSPlatformListResponse,
} from '@/utils/http-client';

export async function getNotificationEvents(
  websiteId: string,
  dateRange?: Date[]
): Promise<StrapiHsmEvent[]> {
  const client = TechnicalSolutionsPlatformHttpClient.instance;

  let dateFrom = dayjs().subtract(1, 'month').toDate();
  let dateTo = new Date();

  if (dateRange && dateRange.length === 2) {
    dateFrom = dateRange[0];
    dateTo = dateRange[1];
  }

  const { data } = await client.get<TSPlatformListResponse<StrapiHsmEvent>>(
    getNotificationEventsUrl(websiteId, dateFrom, dateTo)
  );

  return data;
}
