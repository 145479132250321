import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import spanishTranslation from './es.json';
import englishTranslation from './en.json';

export const resources = {
  en: { translation: englishTranslation },
  es: { translation: spanishTranslation },
};

void use(initReactI18next).init({
  resources,
  lng: 'es',
  interpolation: {
    escapeValue: false,
  },
});
