import { useRouteError } from 'react-router-dom';

const RouteErrorBoundary = () => {
  const error = useRouteError() as Error;

  return (
    <div>
      <span>{error.message}</span>
    </div>
  );
};

export default RouteErrorBoundary;
