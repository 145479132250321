import { Flex } from '@chakra-ui/react';

import { MainProps } from './main.types';

const Main = ({ children }: MainProps) => {
  return (
    <Flex justifyContent={'center'} alignItems={'center'}>
      <Flex
        flex={'1'}
        direction="column"
        justifyContent={'center'}
        p={{ xs: 5, md: 10 }}
      >
        <Flex flex={'1'} justifyContent="center">
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Main;
