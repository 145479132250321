export const APP_NAME: string = process.env.APP_NAME || 'ts-hsm-manager';

export const LOGIN_URL: string =
  process.env.LOGIN_URL || 'https://www.login.stagecliengo.com';

export const MOCKED_AUTH: boolean = process.env.MOCKED_AUTH === 'true' || false;

export const CLIENGO_API_URL: string =
  process.env.CLIENGO_API_URL || 'https://api.stagecliengo.com/1.0';

export const TECHNICAL_SOLUTIONS_PLATFORM_URL: string =
  process.env.TECHNICAL_SOLUTIONS_PLATFORM_URL ||
  'https://technical-solutions-platform.devcliengo.com/api';

export const TECHNICAL_SOLUTIONS_PLATFORM_TOKEN: string =
  process.env.TECHNICAL_SOLUTIONS_PLATFORM_TOKEN ||
  '4eca9d965acd12c0600b53fe82a1984ee37cd39633997cd1730dea8da56c59602032ad7e1210ed6e165fcf4ddced1c81dfb1276a97565c0029a127402b842e206745f83f1921bc65c0f45b0eac30cf4943485dbad3528f2d9997b6658913b5eb96056e890fe7d80db9a183e5642ff7118520661c66a505a66fc43d73966101ec';

export const TECHNICAL_SOLUTIONS_API_URL: string =
  process.env.TECHNICAL_SOLUTIONS_API_URL ||
  'https://technical-solutions-api.devcliengo.com/';

export const CORS_ANYWERE_URL: string =
  process.env.CORS_ANYWERE_URL ||
  'https://cliengo-cors-anywere-8b605de04889.herokuapp.com/';

export const SEGMENT_WRITE_KEY: string =
  process.env.SEGMENT_WRITE_KEY || '7WHI36JKuhdMtRxpJ0XmEivgGQzIztLN';

export const MAIN_HELP_LINK: string =
  process.env.MAIN_HELP_LINK ||
  'https://help.cliengo.com/hc/es/articles/8888445953051-Mensajes-salientes-de-WhatsApp-Business-HSMs-con-Cliengo';

export const TEMPLATE_HELP_LINK: string =
  process.env.TEMPLATE_HELP_LINK ||
  'https://help.cliengo.com/hc/es/articles/19859693508763';
