import dayjs from 'dayjs';

import { Campaign, Stats } from './types';
import { getCampaignsReportUrl } from './urls';

import {
  TechnicalSolutionsApiHttpClient,
  TechnicalSolutionsApiResponse,
} from '@/utils/http-client';
/**
 * Return the campaigns of a cliengo website
 * @param websiteId
 * @returns an array of Campaign
 */
export async function getCampaignsAndStats(
  websiteId: string,
  dateRange?: Date[]
): Promise<{
  campaigns: Campaign[];
  stats: Stats;
}> {
  const client = TechnicalSolutionsApiHttpClient.instance;

  let dateFrom = dayjs().subtract(1, 'month').toDate();
  let dateTo = new Date();

  if (dateRange && dateRange.length === 2) {
    dateFrom = dateRange[0];
    dateTo = dateRange[1];
  }

  const { result } = await client.get<
    TechnicalSolutionsApiResponse<{ campaigns: Campaign[]; stats: Stats }>
  >(getCampaignsReportUrl(websiteId, dateFrom, dateTo));

  return result;
}
