import { Website } from '@cliengo/types';

import { updateTemplateUrl } from '../urls';
import { TemplateResponseDto } from '../types';

import {
  TechnicalSolutionsApiHttpClient,
  TSPlatformSingleResponse,
} from '@/utils/http-client';
import { StrapiTemplate } from '@/services/technical-solutions-platform/types';

/**
 * Update a template for a website
 * @param websiteId Cliengo website
 * @param templateId Strapi template
 * @param newValues Strapi template property values
 * @returns an Strapi template
 */
async function updateTemplate(
  websiteId: Website['id'],
  templateId: TemplateResponseDto['id'],
  newValues: Partial<TemplateResponseDto>
): Promise<StrapiTemplate> {
  const client = TechnicalSolutionsApiHttpClient.instance;

  const requestOptions = JSON.stringify(newValues);

  const { data: updatedTemplate } = await client.put<
    TSPlatformSingleResponse<StrapiTemplate>
  >(updateTemplateUrl(websiteId, templateId), requestOptions);

  return updatedTemplate;
}

export default updateTemplate;
