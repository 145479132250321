import _ from 'lodash';

import {
  DrawerState,
  DRAWER_CONTEXT_VALUE_TYPE,
  MANAGER_VIEW_VALUE_TYPE,
} from './layout.types';

import {
  DRAWERABLE_MANAGER_VIEWS,
  TABS_MANAGER_VIEWS,
} from '@/constants/layout';

type CONTEXT_STATUS_KEY_TEMPLATE_TYPE =
  `is${Capitalize<DRAWER_CONTEXT_VALUE_TYPE>}`;

export const updateContextStatus = (
  contextStatus: DrawerState['contextStatus'],
  context: DRAWER_CONTEXT_VALUE_TYPE
) => {
  const newState = _.cloneDeep(contextStatus);

  const contextKey = `is${_.upperFirst(context)}`;

  if (!newState.hasOwnProperty(contextKey)) {
    console.warn('Invalid context:', context);
    return contextStatus;
  }

  _.keys(newState).forEach((key) => {
    newState[key as CONTEXT_STATUS_KEY_TEMPLATE_TYPE] = false;
  });

  newState[contextKey as CONTEXT_STATUS_KEY_TEMPLATE_TYPE] = true;

  return newState;
};

export const isDrawerableView = (value: MANAGER_VIEW_VALUE_TYPE): boolean => {
  return DRAWERABLE_MANAGER_VIEWS.includes(value);
};

export const isTabsView = (value: MANAGER_VIEW_VALUE_TYPE): boolean => {
  return TABS_MANAGER_VIEWS.includes(value);
};
