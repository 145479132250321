import { HsmEventStatus } from '@/constants/hsm-event-status';
import {
  StrapiContactList,
  StrapiTemplate,
  StrapiHsmEvent,
} from '@/services/technical-solutions-platform/types';

export type ContactsSource = 'GOOGLE_SHEET' | 'CONTACT_LIST' | 'IMPORTED_FILE';

export type DeliveryMethod = 'SEND_NOW' | 'SCHEDULE';

export enum CampaignStatus {
  SENDING = 'SENDING',
  SENT = 'SENT',
  SCHEDULED = 'SCHEDULED',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
  PREPARED = 'PREPARED',
  RESENDING = 'RESENDING',
  STOPPED = 'STOPPED',
}

export interface CreateCampaignDTO {
  websiteId: string;
  name: string;
  templateId: number;
  contactsSource: ContactsSource;
  contactListId: number | null;
}

export interface CampaignFormDTO {
  websiteId: string;
  name: string;
  template: StrapiTemplate | null;
  templateId: number;
  contactsSource: ContactsSource;
  contactList: StrapiContactList | null;
  contactListId: number | null;
  deliveryMethod: DeliveryMethod;
  scheduled: boolean;
  scheduleDateTime: Date | null;
  file: File | null;
}

export interface CreateCampaignV3DTO {
  websiteId: string;
  campaignName: string;
  templateId: string;
  contactsSource: ContactsSource;
  contactListId: string | null;
  scheduled: boolean;
  scheduleDateTime: Date | null;
}

export interface CancelCampaignFormDTO {
  websiteId: string;
  campaignId: number;
  cancellationReason: string;
}

export interface Campaign {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  scheduleDateTime: string | null;
  eventCounter: EventCounter;
  contactsSourceLength: number;
  totalEventCounter: number;
  totalProcessedEvents: number;
  totalPendingEvents: number;
  totalSentEvents: number;
  totalUndeliveredEvents: number;
}

export type EventCounter = Record<HsmEventStatus, number>;

export interface Stats {
  from: string;
  to: string;
  limitByPlan: number;
  available: number;
  internEvents: number;
  campaignEvents: number;
  notificationEvents: number;
  totalPendingEvents: number;
  totalSentEvents: number;
  totalUndeliveredEvents: number;
}

export interface FullCampaign {
  id: number;
  name: string;
  createdAt: string;
  status: string;
  scheduleDateTime: string;
  updatedAt: string;
  contactsSourceLength: number;
  contactsSource: string;
  errorMessage: string | null;
  cancelledAt: string | null;
  cancellationUser: string | null;
  cancellationReason: string | null;
  contactsSourceName: string | null;

  template: StrapiTemplate;

  eventCounter: EventCounter;
  totalEventCounter: number;
  totalProcessedEvents: number;

  events: StrapiHsmEvent[];
}
