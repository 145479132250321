/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createTemplateV2Url, uploadTemplateMediaUrl } from './urls';
import { CreateTemplateDTO, TemplateType } from './types';

import { StrapiTemplate } from '@/services/technical-solutions-platform/types';
import {
  TechnicalSolutionsApiHttpClient,
  TechnicalSolutionsApiResponse,
} from '@/utils/http-client';
/**
 * Creates a Gupshup template
 * @param websiteId cliengo website Id which owns the template
 * @param templateBody object with the props of the new template
 * @param file media file required when template type
 * is one of: 'IMAGE', 'VIDEO', 'DOCUMENT'
 * @returns the created template
 */
export async function createTemplateV2(
  websiteId: string,
  templateBody: CreateTemplateDTO,
  file: File | null
): Promise<StrapiTemplate> {
  const client = TechnicalSolutionsApiHttpClient.instance;

  let mediaId;

  if (
    [TemplateType.IMAGE, TemplateType.VIDEO, TemplateType.DOCUMENT].includes(
      templateBody.type
    )
  ) {
    const formData = new FormData();

    formData.append('file', file!);
    formData.append('templateType', templateBody.type);

    const url1 = uploadTemplateMediaUrl(websiteId);
    const mediaFileResponse = await client.postFormData<
      TechnicalSolutionsApiResponse<{ mediaId: string }>
    >(url1, formData);

    mediaId = mediaFileResponse.result.mediaId;
  }

  const url2 = createTemplateV2Url(websiteId);
  const templateResponse = await client.post<
    TechnicalSolutionsApiResponse<{ data: StrapiTemplate }>
  >(url2, { ...templateBody, mediaId });

  return templateResponse.result.data;
}
