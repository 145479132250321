export interface TrackEvent {
  eventName: string;
  category: string;
  action: string;
  label: string;
}

export interface EVENTS {
  landing: {
    header: {
      call_to_actions: Array<TrackEvent>;
    };
  };
  manager: {
    layout: {
      navbar: {
        tabs: Array<TrackEvent>;
      };
    };
    sections: {
      messages: {
        heading_help_redirect: TrackEvent;
        show_create_campaign: TrackEvent;
        show_help_use: TrackEvent;
        create_campaign: {
          template_type: TrackEvent;
          shipping_type: TrackEvent;
          source_contact_type: TrackEvent;
          tier_help_redirect: TrackEvent;
        };
      };
      templates: {
        create_template: {
          template_category: TrackEvent;
          template_type: TrackEvent;
          template_language: TrackEvent;
        };
        update_template: {
          update_file: TrackEvent;
          inactive_template: TrackEvent;
          confirm_inactive_template: TrackEvent;
        };
      };
      contact_list: {
        export: TrackEvent;
        show_create_contact_list: TrackEvent;
        create_contact_list: TrackEvent;
        create_contact_list_form: {
          upload_file: TrackEvent;
          verify_file: TrackEvent;
        };
      };
    };
  };
}

export const events: EVENTS = {
  landing: {
    header: {
      call_to_actions: [
        {
          eventName: 'wam_hsm_landing',
          category: 'hsm_manager',
          action: 'try_hsm',
          label: 'wa_marketing',
        },
        {
          eventName: 'wam_hsm_landing',
          category: 'hsm_manager',
          action: 'try_hsm',
          label: 'wa_reopenings',
        },
        {
          eventName: 'wam_hsm_landing',
          category: 'hsm_manager',
          action: 'try_hsm',
          label: 'wa_autonotifications',
        },
      ],
    },
  },
  manager: {
    layout: {
      navbar: {
        tabs: [
          {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_navigation',
            action: 'change_tab',
            label: 'messages',
          },
          {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_navigation',
            action: 'change_tab',
            label: 'templates',
          },
          {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_navigation',
            action: 'change_tab',
            label: 'contact_list',
          },
          {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_navigation',
            action: 'change_tab',
            label: 'blocked_numbers',
          },
        ],
      },
    },
    sections: {
      messages: {
        heading_help_redirect: {
          eventName: 'wam_hsm_manager',
          category: 'hsm_manager_messages',
          action: 'go_to_help',
          label: 'hsm_main',
        },
        show_create_campaign: {
          eventName: 'wam_hsm_manager',
          category: 'hsm_manager_messages',
          action: 'show_create_campaign',
          label: 'hsm_main',
        },
        show_help_use: {
          eventName: 'wam_hsm_manager',
          category: 'hsm_manager',
          action: 'acknowledge',
          label: 'new_campaign',
        },
        create_campaign: {
          template_type: {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_campaign',
            action: 'select_template_type',
            label: 'hsm_main',
          },
          shipping_type: {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_campaign',
            action: 'option_shipping_type',
            label: 'hsm_main',
          },
          source_contact_type: {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_campaign',
            action: 'option_source_contact_type',
            label: 'hsm_main',
          },
          tier_help_redirect: {
            eventName: 'wam_hsm_manager',
            category: 'go_to_help',
            action: 'hsm_manager',
            label: 'limit_create_campaign',
          },
        },
      },
      templates: {
        create_template: {
          template_category: {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_template',
            action: 'select_template_category',
            label: 'hsm_main',
          },
          template_type: {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_template',
            action: 'select_template_type',
            label: 'hsm_main',
          },
          template_language: {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_template',
            action: 'select_language',
            label: 'hsm_main',
          },
        },
        update_template: {
          update_file: {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_template',
            action: 'update_file',
            label: 'hsm_main',
          },
          inactive_template: {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_template',
            action: 'inactive_template',
            label: 'hsm_main',
          },
          confirm_inactive_template: {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_template',
            action: 'confirm_inactive_template',
            label: 'hsm_main',
          },
        },
      },
      contact_list: {
        export: {
          eventName: 'wam_hsm_manager',
          category: 'hsm_manager_contacts',
          action: 'export_contact_list',
          label: 'hsm_main',
        },
        show_create_contact_list: {
          eventName: 'wam_hsm_manager',
          category: 'hsm_manager_contacts',
          action: 'show_create_contact_list',
          label: 'hsm_main',
        },
        create_contact_list: {
          eventName: 'wam_hsm_manager',
          category: 'hsm_manager_contacts',
          action: 'create_contact_list',
          label: 'hsm_main',
        },
        create_contact_list_form: {
          upload_file: {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_contact_list',
            action: 'upload_file',
            label: 'hsm_main',
          },
          verify_file: {
            eventName: 'wam_hsm_manager',
            category: 'hsm_manager_contact_list',
            action: 'verify_file',
            label: 'hsm_main',
          },
        },
      },
    },
  },
};
