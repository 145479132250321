/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { CommonState, TS_API_REJECT_VALUE_TYPE } from '../common/common.types';
import { toggleShowDrawer } from '../layout';

import { createTemplateV2 } from '@/services/technical-solutions-api/create-template-v2';
import { getTemplates } from '@/services/technical-solutions-api/get-templates';
import updateTemplate from '@/services/technical-solutions-api/put-templates';
import {
  CreateTemplateDTO,
  TemplateResponseDto,
  TemplateType,
} from '@/services/technical-solutions-api/types';
import { uploadTemplateMedia } from '@/services/technical-solutions-api/upload-template-media';
import { StrapiTemplate } from '@/services/technical-solutions-platform/types';

interface FetchTemplatesActionParams {
  websiteId: CommonState['currentChannel'];
}

interface CreateTemplateActionParams {
  websiteId: CommonState['currentChannel'];
  template: CreateTemplateDTO;
  mediaFile: File | null;
}

export interface DeactivateTemplateActionParams {
  websiteId: CommonState['currentChannel'];
  templateId: TemplateResponseDto['id'];
  newValues: Partial<TemplateResponseDto>;
}

export interface UpdateTemplateMediaActionParams {
  websiteId: CommonState['currentChannel'];
  templateId: TemplateResponseDto['id'];
  templateType: TemplateType;
  file: File;
}

export const fetchTemplatesAction = createAsyncThunk(
  'templates/fetchTemplates',
  async ({ websiteId }: FetchTemplatesActionParams) => {
    const templates: TemplateResponseDto[] = await getTemplates(websiteId);

    return templates;
  }
);

export const createTemplateAction = createAsyncThunk<
  StrapiTemplate,
  CreateTemplateActionParams,
  { rejectValue: TS_API_REJECT_VALUE_TYPE }
>(
  'templates/createTemplate',
  async (
    { websiteId, template, mediaFile }: CreateTemplateActionParams,
    thunkAPI
  ) => {
    try {
      const newTemplate: StrapiTemplate = await createTemplateV2(
        websiteId,
        template,
        mediaFile
      );

      return newTemplate;
    } catch (error) {
      if ((error as AxiosError)?.response?.data) {
        const rejectValue = (error as AxiosError).response
          ?.data as TS_API_REJECT_VALUE_TYPE;

        return thunkAPI.rejectWithValue(rejectValue);
      }

      throw error;
    }
  }
);

export const deactivateTemplateAction = createAsyncThunk(
  'templates/deactivateTemplate',
  async (
    { websiteId, templateId, newValues }: DeactivateTemplateActionParams,
    thunkAPI
  ) => {
    const updatedTemplate: StrapiTemplate = await updateTemplate(
      websiteId,
      templateId,
      newValues
    );

    void thunkAPI.dispatch(fetchTemplatesAction({ websiteId }));
    thunkAPI.dispatch(toggleShowDrawer());

    return updatedTemplate;
  }
);

export const updateTemplateMediaAction = createAsyncThunk(
  'templates/updateTemplateMedia',
  async (
    {
      websiteId,
      templateId,
      templateType,
      file,
    }: UpdateTemplateMediaActionParams,
    thunkAPI
  ) => {
    try {
      let mediaId: string | null = null;

      mediaId = await uploadTemplateMedia({
        websiteId,
        templateType,
        file,
      });

      const updatedTemplate: StrapiTemplate = await updateTemplate(
        websiteId,
        templateId,
        { mediaId }
      );

      void thunkAPI.dispatch(fetchTemplatesAction({ websiteId }));

      return updatedTemplate;
    } catch (error) {
      if ((error as AxiosError)?.response?.data) {
        const rejectValue = (error as AxiosError).response
          ?.data as TS_API_REJECT_VALUE_TYPE;

        return thunkAPI.rejectWithValue(rejectValue);
      }
    }
  }
);
