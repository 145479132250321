import { AccountLimitTierResponse } from '../types';
import { getAccountLimitsUrl } from '../urls';

import {
  TechnicalSolutionsApiHttpClient,
  TechnicalSolutionsApiResponse,
} from '@/utils/http-client';

/**
 * Return the account limit info
 * @param websiteId
 * @returns an property account limit
 */
async function getAccountLimits(websiteId: string): Promise<{
  accountLimit: AccountLimitTierResponse['accountLimit'];
}> {
  const client = TechnicalSolutionsApiHttpClient.instance;

  const { result } = await client.get<
    TechnicalSolutionsApiResponse<AccountLimitTierResponse>
  >(getAccountLimitsUrl(websiteId));

  return result;
}

export default getAccountLimits;
