/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/unbound-method */
import { Website } from '@cliengo/types';
import { ReactNode, useContext, useEffect } from 'react';

import { JwtCliengoPayload } from './types';
import { UserContext } from './user-context-provider';
import { validateJwtAndAuthorize } from './validate-jwt-and-authorize';

/**
 * `AuthenticationGuard` is a component responsible for verifying the user's
 * authentication using an authorization guard. Upon successful verification,
 * it updates the user context with the JWT payload and the list of websites.
 *
 * @component
 *
 * @param {Object} props - Component properties.
 * @param {ReactNode} props.children - The child elements to be rendered within the component.
 *
 * @returns {JSX.Element} - Returns the child elements once authentication is verified.
 *
 * @example
 * <AuthenticationGuard>
 *   <ProtectedComponent />
 * </AuthenticationGuard>
 */
export const AuthenticationGuard = ({ children }: { children: ReactNode }) => {
  const { setJwtCliengoPayload, setWebsites } = useContext(UserContext);

  const onAuthenticationVerified = (jcp: JwtCliengoPayload, ws: Website[]) => {
    setJwtCliengoPayload(jcp);
    setWebsites(ws);
  };

  const init = async (): Promise<void> => {
    await validateJwtAndAuthorize(onAuthenticationVerified);
  };

  useEffect(() => {
    void init();
  }, []);

  return <>{children}</>;
};
