/* eslint-disable @typescript-eslint/no-empty-function */
import { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { CLIENT_MANAGER_ROUTES, ROOT_ROUTES } from '@/constants/routes';
import { UserContext } from '@/services/auth/user-context-provider';
import DefaultProgress from '@/components/default-progress';

export const Redirect = () => {
  const { websites } = useContext(UserContext);

  useEffect(() => {}, [websites]);

  if (websites) {
    if (websites?.length) {
      return <Navigate to={`/${CLIENT_MANAGER_ROUTES.messages}`} />;
    } else {
      return <Navigate to={`/${ROOT_ROUTES.home}`} />;
    }
  }
  return <DefaultProgress />;
};
