import { resendPendingMessagesUrl } from './urls';

import {
  TechnicalSolutionsApiHttpClient,
  TechnicalSolutionsApiResponse,
} from '@/utils/http-client';

/**
 * Sends a request to resend pending messages for a specific website and campaign.
 *
 * @param websiteId The ID of the website for which to resend pending messages.
 * @param campaignId The ID of the campaign for which to resend pending messages.
 * @returns A promise that resolves to an object containing the message indicating the result of the resend operation.
 */
export async function resendPendingMessages(
  websiteId: string,
  campaignId: number
): Promise<{ message: string }> {
  const client = TechnicalSolutionsApiHttpClient.instance;

  const response = await client.put<
    TechnicalSolutionsApiResponse<{ message: string }>
  >(resendPendingMessagesUrl(websiteId, campaignId));

  return response.result;
}
