export enum TemplateCategory {
  UTILITY = 'UTILITY',
  // AUTHENTICATION = 'AUTHENTICATION',
  MARKETING = 'MARKETING',
  TRANSACTIONAL = 'TRANSACTIONAL',
}

export enum TemplateType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
}

export enum TemplateLanguageCode {
  es = 'es',
  en = 'en',
}

export enum TemplateReasonsRejections {
  ABUSIVE_CONTENT = 'ABUSIVE_CONTENT',
  INCORRECT_CATEGORY = 'INCORRECT_CATEGORY',
  SCAM = 'SCAM',
  INVALID_FORMAT = 'INVALID_FORMAT',
  NONE = 'NONE',
}

export interface CreateTemplateDTO {
  category: TemplateCategory;
  type: TemplateType;
  languageCode: TemplateLanguageCode;
  name: string;
  text: string;
  description: string;
  mediaId?: string | null;
}

export interface TemplateResponseDto {
  id: number;
  text: string;
  status: string;
  name: string;
  description: string;
  gupshupTemplateId: string;
  mediaUrl: null | string;
  type: string;
  createdAt: string;
  updatedAt: string;
  category: string;
  reason: null | string;
  mediaId: string | null;
  buttons: unknown[] | null;
  elementName: string | null;
  mediaUpdatedAt: string | null;
  isMediaOverdue: boolean;
}
