import { useLocation, useNavigate } from 'react-router-dom';

import { DRAWER_CONTEXT_VALUE_TYPE } from '@/store/slices/layout/layout.types';

const useHandleContext = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getCurrentContext = () => {
    const queryParams = new URLSearchParams(location.search);

    return queryParams.get('context');
  };

  const setContext = (newContextValue: DRAWER_CONTEXT_VALUE_TYPE) => {
    const queryParams = new URLSearchParams(location.search);

    queryParams.set('context', newContextValue);

    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  const addContext = (contextValue: DRAWER_CONTEXT_VALUE_TYPE) => {
    const queryParams = new URLSearchParams(location.search);

    if (!queryParams.has('context')) {
      queryParams.set('context', contextValue);
      navigate({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
    }
  };

  const removeContext = () => {
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete('context');

    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  return { getCurrentContext, setContext, addContext, removeContext };
};

export default useHandleContext;
