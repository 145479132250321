import { createSlice } from '@reduxjs/toolkit';

import { CommonState } from './common.types';
import {
  fetchAccountLimitReducer,
  initializeCommonReducer,
  setCurrentChannelReducer,
  setProviderClientReducer,
} from './common.reducers';
import { fetchAccountLimitAction } from './common.async-actions';

import { asyncThunkStatus } from '@/constants/common';

export const initialState: CommonState = {
  currentChannel: '',
  gupshupClient: false,
  tierCurrentLimit: null,
  fetchAccountLimitStatus: asyncThunkStatus.idle,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    initializeCommon: initializeCommonReducer,
    fetchAccountLimit: fetchAccountLimitReducer,
    setCurrentChannel: setCurrentChannelReducer,
    setProviderClient: setProviderClientReducer,
  },
  extraReducers(builder) {
    builder.addCase(fetchAccountLimitAction.pending, (state: CommonState) => {
      state.fetchAccountLimitStatus = asyncThunkStatus.pending;
    });
    builder.addCase(fetchAccountLimitAction.fulfilled, (state: CommonState) => {
      state.fetchAccountLimitStatus = asyncThunkStatus.succeeded;
    });
    builder.addCase(fetchAccountLimitAction.rejected, (state: CommonState) => {
      state.fetchAccountLimitStatus = asyncThunkStatus.failed;
    });
  },
});

export const {
  initializeCommon,
  fetchAccountLimit,
  setCurrentChannel,
  setProviderClient,
} = commonSlice.actions;

export default commonSlice.reducer;
