import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';

import { store } from '@/store';
import chakraTheme from '@/theme/chakra';
import { App } from '@/App';
import '@fontsource/nunito/400.css';
import '@/i18n';

createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <ChakraProvider theme={chakraTheme}>
      <App />
    </ChakraProvider>
  </Provider>
);
