import { createAsyncThunk } from '@reduxjs/toolkit';

import { CommonState } from '../common/common.types';

import { fetchAccountLimit } from './common.slice';

import { AccountLimitTierResponse } from '@/services/technical-solutions-api/types';
import getAccountLimits from '@/services/technical-solutions-api/get-account-limits';
// TODO: agregar tests para consulta de limites
interface FetchAccountLimit {
  websiteId: CommonState['currentChannel'];
}

export const fetchAccountLimitAction = createAsyncThunk(
  'common/fetchAccountLimit',
  async ({ websiteId }: FetchAccountLimit, thunkAPI) => {
    const { accountLimit }: AccountLimitTierResponse = await getAccountLimits(
      websiteId
    );

    thunkAPI.dispatch(fetchAccountLimit(accountLimit));

    return accountLimit;
  }
);
