import { Flex } from '@chakra-ui/react';
import { camelCase } from 'lodash';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import DrawerContent from '@/components/drawer-content';
import DrawerContext from '@/components/drawer-context';
import Show from '@/components/show';
import useHandleContext from '@/hooks/use-handle-context';
import { useAppDispatch, useAppSelector } from '@/hooks/use-redux';
import Main from '@/layout/main';
import Navbar from '@/layout/navbar';
import { initializeLayout, toggleShowDrawer } from '@/store/slices/layout';
import {
  DRAWER_CONTEXT_VALUE_TYPE,
  LayoutState,
  MANAGER_VIEW_VALUE_TYPE,
} from '@/store/slices/layout/layout.types';

const RootShell = () => {
  const dispatch = useAppDispatch();

  const { getCurrentContext, removeContext } = useHandleContext();

  const { pathname } = useLocation();

  const currentContext = getCurrentContext();

  const {
    hasDrawer,
    drawer: { isOpen },
  } = useAppSelector<LayoutState>((state) => state.layout);

  useEffect(() => {
    dispatch(
      initializeLayout({
        managerView: camelCase(
          pathname.split('/').pop()
        ) as MANAGER_VIEW_VALUE_TYPE,
        selectedContext: currentContext as DRAWER_CONTEXT_VALUE_TYPE,
      })
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pathname, currentContext]);

  const handleCloseDrawer = () => {
    removeContext();
    dispatch(toggleShowDrawer());
  };

  return (
    <Main>
      <Flex flex={1} flexDirection={'column'}>
        <Navbar />
        <Outlet />
        <Show when={hasDrawer}>
          <DrawerContext isOpen={isOpen} onClose={handleCloseDrawer}>
            <DrawerContent />
          </DrawerContext>
        </Show>
      </Flex>
    </Main>
  );
};

export default RootShell;
