import { createAsyncThunk } from '@reduxjs/toolkit';

import { CommonState } from '../common/common.types';
import { GlobalState } from '../types';

import {
  setCampaigns,
  setCurrentCampaign,
  setNotifications,
} from './messages.slice';
import { MessagesState } from './messages.types';

import { getCampaignsAndStats } from '@/services/technical-solutions-api/get-campaigns-and-stats';
import { resendPendingMessages } from '@/services/technical-solutions-api/resend-pending-messages';
import { Campaign, Stats } from '@/services/technical-solutions-api/types';
import { getNotificationEvents } from '@/services/technical-solutions-platform/get-notification-events';
import { StrapiHsmEvent } from '@/services/technical-solutions-platform/types';
import { getFullCampaign } from '@/services/technical-solutions-api/get-full-campaign';

// TODO: tests para consulta de limites
interface FetchCampaignsAndStats {
  websiteId: CommonState['currentChannel'];
  dateRange: MessagesState['dateRange'];
}

interface FetchCurrentCampaign {
  campaignId: string;
}

type FetchNotificationEvents = FetchCampaignsAndStats;

export const fetchCampaignsAndStatsAction = createAsyncThunk(
  'messages/fetchCampaignsAndStats',
  async ({ websiteId, dateRange }: FetchCampaignsAndStats, thunkAPI) => {
    const { campaigns, stats }: { campaigns: Campaign[]; stats: Stats } =
      await getCampaignsAndStats(websiteId, dateRange);

    thunkAPI.dispatch(setCampaigns({ campaigns, stats }));

    return { campaigns, stats };
  }
);

export const fetchNotificationEventsAction = createAsyncThunk(
  'messages/fetchNotificationEvent',
  async ({ websiteId, dateRange }: FetchNotificationEvents, thunkAPI) => {
    const notifications: StrapiHsmEvent[] = await getNotificationEvents(
      websiteId,
      dateRange
    );

    thunkAPI.dispatch(setNotifications({ notifications }));

    return notifications;
  }
);

export const fetchCurrentCampaignAction = createAsyncThunk(
  'messages/fetchCurrentCampaign',
  async ({ campaignId }: FetchCurrentCampaign, thunkAPI) => {
    const {
      common: { currentChannel },
    } = thunkAPI.getState() as GlobalState;

    const currentCampaign = await getFullCampaign(currentChannel, campaignId);

    thunkAPI.dispatch(setCurrentCampaign({ currentCampaign }));

    return currentCampaign;
  }
);

export const resendPendingMessagesAction = createAsyncThunk(
  'messages/resendPendingMessages',
  async (_, thunkAPI) => {
    const {
      messages: { currentCampaign },
      common: { currentChannel },
    } = thunkAPI.getState() as GlobalState;

    if (currentCampaign) {
      const result = await resendPendingMessages(
        currentChannel,
        currentCampaign['id']
      );

      return result;
    }

    return thunkAPI.rejectWithValue('currentCampaign is null');
  }
);
