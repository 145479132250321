import { CliengoLoader } from 'cliengify';
import { lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import { Redirect } from './redirect';

import RootShell from '@/components/root-shell';
import RouteErrorBoundary from '@/components/route-error-boundary';
import { CLIENT_MANAGER_ROUTES, ROOT_ROUTES } from '@/constants/routes';
import { UserProvider } from '@/services/auth/user-context-provider';
import { AuthenticationGuard } from '@/services/auth';

const HomeView = lazy(() => import('@/views/home'));
const MessagesView = lazy(() => import('@/components/messages-page'));
const TemplatesView = lazy(() => import('@/components/templates-page'));
const ContactListsView = lazy(() => import('@/components/contact-lists-page'));
const BlacklistView = lazy(() => import('@/components/blacklist-page'));

const router = createBrowserRouter([
  {
    path: '',
    element: (
      <UserProvider>
        <AuthenticationGuard>
          <Redirect />
        </AuthenticationGuard>
      </UserProvider>
    ),
  },
  {
    path: `/`,
    element: <Outlet />,
    children: [
      {
        index: true,
        path: `${ROOT_ROUTES.home}`,
        element: (
          <Suspense fallback={<CliengoLoader isLoading />}>
            <HomeView />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: `/`,
    element: (
      <UserProvider>
        <AuthenticationGuard>
          <RootShell />
        </AuthenticationGuard>
      </UserProvider>
    ),
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        path: `${CLIENT_MANAGER_ROUTES.messages}`,
        element: (
          <Suspense fallback={<CliengoLoader isLoading />}>
            <MessagesView />
          </Suspense>
        ),
      },
      {
        path: `${CLIENT_MANAGER_ROUTES.templates}`,
        element: (
          <Suspense fallback={<CliengoLoader isLoading />}>
            <TemplatesView />
          </Suspense>
        ),
      },
      {
        path: `${CLIENT_MANAGER_ROUTES.contactLists}`,
        element: (
          <Suspense fallback={<CliengoLoader isLoading />}>
            <ContactListsView />
          </Suspense>
        ),
      },
      {
        path: `${CLIENT_MANAGER_ROUTES.blacklist}`,
        element: (
          <Suspense fallback={<CliengoLoader isLoading />}>
            <BlacklistView />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: `/*`,
    element: <Navigate to={`/${ROOT_ROUTES.home}`} />,
  },
]);

export default router;
