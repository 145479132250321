import { Website } from '@cliengo/types';

import { getHsmWebsitesUrl } from './urls';

import {
  TechnicalSolutionsApiHttpClient,
  TechnicalSolutionsApiResponseList,
} from '@/utils/http-client';

export async function getHsmWebsites(jwt: string): Promise<Website[]> {
  const client = TechnicalSolutionsApiHttpClient.instance;

  client.setAuthorization(jwt);

  const { result } = await client.get<
    TechnicalSolutionsApiResponseList<Website>
  >(getHsmWebsitesUrl);

  return result;
}
